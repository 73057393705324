import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43e16550"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "picture" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "gallery-el-type" }
const _hoisted_4 = { class: "gallery-el-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["gallery-el wow-total animate__fadeInUp", _ctx.page])
  }, [
    _createElementVNode("picture", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.elementData['galleryListProductPreview'],
        alt: "gallery element photo",
        class: "img"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.elementData['galleryListProductType']), 1),
    _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.elementData['galleryListProductName']), 1)
  ], 2))
}