
  import {defineComponent} from "vue";
  import AppLogo from "@/components/AppLogo.vue";

  export default defineComponent({
    name: "AppHeader",
    components: {AppLogo},
    props: {
      commonData: Object,
      playPreview: Boolean
    },
    emits:{
      mobileMenu:(trigger: boolean) => trigger
    },
    data() {
      return {
        isDesktop: window.matchMedia('(min-width: 1110px)').matches
      }
    },
  })
