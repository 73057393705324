import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e4a5b0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "whatWeDo-block" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "list" }
const _hoisted_5 = { class: "list-el-title" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", {
        class: "title wow-total animate__fadeInUp",
        innerHTML: _ctx.pageData['whatWeDoTitle']
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageData['whatWeDoPoints'], (listEl) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "list-el wow-total animate__fadeInUp",
            key: listEl['whatWeDoPointsTitle']
          }, [
            _createElementVNode("h3", _hoisted_5, _toDisplayString(listEl['whatWeDoPointsTitle']), 1),
            _createElementVNode("p", {
              class: "list-el-text",
              innerHTML: listEl['whatWeDoPointsText']
            }, null, 8, _hoisted_6)
          ]))
        }), 128))
      ])
    ])
  ]))
}