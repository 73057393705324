
  import {defineComponent} from "vue";

  export default defineComponent({
    name: "AppHomeBanner",
    props: {
      pageData: Object,
      preview: Boolean
    },
    emits:{
      previewActionHomeBanner:(trigger: boolean) => trigger,
      videoBannerAction:(trigger: boolean) => trigger,
    },
    data() {
      return {
        isMobile: window.matchMedia('(max-width: 660px)').matches,
        play: false
      }
    },
    methods:{
      checkVideo(){
        if(!window.matchMedia('(min-width: 660px) and (max-width: 1025px)').matches) {
          document.body.style.overflowY = "hidden";
        }
        this.$emit('previewActionHomeBanner', true);
        this.play = true;
        this.$emit('videoBannerAction', false);
        setTimeout(() => {
          document.body.style.overflowY = "auto"
          this.$emit('previewActionHomeBanner', false)
          this.play = false;
        }, 9500)
      }
    },
    unmounted() {
      document.body.style.overflowY = "auto"
      this.$emit('previewActionHomeBanner', false)
      this.play = false;
    }
  })
