
import {defineComponent} from "vue";
import AppGalleryModal from "@/components/common-blocks/galleryModal/AppGalleryModal.vue";
import AppGalleryEl from "@/components/common-blocks/gallery/AppGalleryEl.vue";

export default defineComponent({
  name: "AppGallery",
  props: {
    pageData: Object
  },
  components: {AppGalleryEl, AppGalleryModal},
  data() {
    return {
      galleryModalActive: false,
      currentIndex: 0,
      modalChecked: false
    }
  },
  computed: {
    routeHash() {
      return this.$route['hash'];
    },
    routePath() {
      return this.$route['path'];
    },
    routeName() {
      return this.$route['name'];
    },
    galleryList() {
      if (typeof this.pageData === "object") {
        return this.pageData['galleryList']
      }
      return []
    }
  },
  methods: {
    galleryModal(trigger: boolean, cardIndex: number, hashName: string) {
      this.galleryModalActive = trigger;
      this.currentIndex = cardIndex;
      trigger ? document.body.style.overflowY = "hidden" : document.body.style.overflowY = "auto"
      if (trigger && !window.location.hash && this.routeName !== "home") {
        location.hash = hashName
      } else if (!trigger) {
        history.replaceState(undefined, '', this.routePath)
      }
    },
    galleryModalHash(galleryData: number[]) {
      if (
          !this.modalChecked &&
          this.routeName != "home" &&
          this.routeHash
      ) {
        let index = galleryData.findIndex((elem: object | any) => {
          return elem['acf']['galleryListProductHash'] === this.routeHash
        })
        if (index >= 0) {
          this.galleryModal(true, index, '')
          this.modalChecked = true
        }
      }
    }
  },
  mounted() {
    this.galleryModalHash(this.galleryList)
  },
  watch: {
    galleryList(newData) {
      this.galleryModalHash(newData)
    }
  }
})
