import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22ccc859"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "footer" }
const _hoisted_3 = { class: "lists-block" }
const _hoisted_4 = { class: "menu" }
const _hoisted_5 = { class: "menu-el" }
const _hoisted_6 = { class: "menu-el" }
const _hoisted_7 = { class: "menu-el" }
const _hoisted_8 = { class: "menu-el" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "contacts-menu" }
const _hoisted_11 = { class: "contacts-el" }
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "contacts-el" }
const _hoisted_14 = ["href"]
const _hoisted_15 = { class: "contacts-el" }
const _hoisted_16 = ["href"]
const _hoisted_17 = { class: "copyrights" }
const _hoisted_18 = ["href"]
const _hoisted_19 = { class: "subtext" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("menu", _hoisted_4, [
            _createElementVNode("li", _hoisted_5, [
              _createVNode(_component_router_link, {
                to: "/web",
                class: "menu-link"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Websites")
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_6, [
              _createVNode(_component_router_link, {
                to: "/applications",
                class: "menu-link"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Applications")
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_7, [
              _createVNode(_component_router_link, {
                to: "/design",
                class: "menu-link"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Design")
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_8, [
              _createElementVNode("a", {
                target: "_blank",
                href: _ctx.commonData['commonContactUs'],
                class: "btn menu-link contact-btn"
              }, "Contact us", 8, _hoisted_9)
            ])
          ]),
          _createElementVNode("ul", _hoisted_10, [
            _createElementVNode("li", _hoisted_11, [
              _createElementVNode("a", {
                target: "_blank",
                href: `tel:${_ctx.commonData['commonPhone']}`,
                class: "contacts-link"
              }, _toDisplayString(_ctx.commonData['commonPhone']), 9, _hoisted_12)
            ]),
            _createElementVNode("li", _hoisted_13, [
              _createElementVNode("a", {
                target: "_blank",
                href: `mailto:${_ctx.commonData['commonEmail']}`,
                class: "contacts-link"
              }, _toDisplayString(_ctx.commonData['commonEmail']), 9, _hoisted_14)
            ]),
            _createElementVNode("li", _hoisted_15, [
              _createElementVNode("a", {
                target: "_blank",
                href: _ctx.commonData['commonTelegram'],
                class: "contacts-link"
              }, "Telegram", 8, _hoisted_16)
            ])
          ])
        ]),
        _createElementVNode("p", _hoisted_17, [
          _createTextVNode(" © " + _toDisplayString(new Date().getFullYear()) + " " + _toDisplayString(_ctx.commonData['commonCopyrights']) + " ", 1),
          _createElementVNode("a", {
            target: "_blank",
            href: _ctx.commonData['commonPrivatePolicy'],
            class: "link"
          }, "Privacy Policy", 8, _hoisted_18)
        ]),
        _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.commonData['commonText']), 1)
      ])
    ])
  ]))
}