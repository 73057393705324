
import {defineComponent} from "vue";
import AppSendRequest from "@/components/common-blocks/AppSendRequest.vue";
import AppGalleryModalVideo from "@/components/common-blocks/galleryModal/AppGalleryModalVideo.vue";
import AppGalleryModalPicture from "@/components/common-blocks/galleryModal/AppGalleryModalPicture.vue";
// eslint-disable-next-line
//@ts-ignore
import WOW from "wow.js"

export default defineComponent({
  name: "AppGalleryModal",
  components: { AppSendRequest, AppGalleryModalVideo, AppGalleryModalPicture},
  props: {
    modalData: Object,
    pageData: Object
  },
  emits:{
    galleryModal:(trigger:boolean, cardIndex:number) => ({trigger,cardIndex})
  },
  mounted() {
    new WOW({
      boxClass: 'wow-modal',
      offset: '100',
      animateClass:'animated-modal',
      scrollContainer: '#modalBlock'
    }).init()
  }
})
