
import {defineComponent} from "vue";

export default defineComponent({
  name: "AppGalleryEl",
  props: {
    elementData: Object,
    page: String
  }
})
