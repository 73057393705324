import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bbe3b80"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-view-wrapper" }
const _hoisted_2 = { class: "homeView" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLoader = _resolveComponent("AppLoader")!
  const _component_AppHomeBanner = _resolveComponent("AppHomeBanner")!
  const _component_AppGallery = _resolveComponent("AppGallery")!
  const _component_AppHomeLetsTalk = _resolveComponent("AppHomeLetsTalk")!
  const _component_AppHomeReviews = _resolveComponent("AppHomeReviews")!
  const _component_AppDevConcepts = _resolveComponent("AppDevConcepts")!
  const _component_AppSendRequest = _resolveComponent("AppSendRequest")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      name: "bubble",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_AppLoader, null, null, 512), [
          [_vShow, _ctx.loader]
        ])
      ]),
      _: 1
    }),
    _createVNode(_Transition, {
      name: "bubble",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AppHomeBanner, {
            pageData: _ctx.pageData,
            preview: _ctx.preview,
            onPreviewActionHomeBanner: _ctx.previewAction,
            onVideoBannerAction: _ctx.videoAction
          }, null, 8, ["pageData", "preview", "onPreviewActionHomeBanner", "onVideoBannerAction"]),
          _createVNode(_component_AppGallery, { pageData: _ctx.pageData }, null, 8, ["pageData"]),
          _createVNode(_component_AppHomeLetsTalk, { pageData: _ctx.pageData }, null, 8, ["pageData"]),
          _createVNode(_component_AppHomeReviews, { pageData: _ctx.pageData }, null, 8, ["pageData"]),
          _createVNode(_component_AppDevConcepts, { pageData: _ctx.pageData }, null, 8, ["pageData"]),
          _createVNode(_component_AppSendRequest, { pageData: _ctx.pageData }, null, 8, ["pageData"])
        ], 512), [
          [_vShow, !_ctx.loader]
        ])
      ]),
      _: 1
    })
  ]))
}