import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { style: {"min-height":"100vh"} }
const _hoisted_2 = {
  key: 1,
  class: "designView"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLoader = _resolveComponent("AppLoader")!
  const _component_AppPagesBanner = _resolveComponent("AppPagesBanner")!
  const _component_AppGallery = _resolveComponent("AppGallery")!
  const _component_AppPagesWhatWeDo = _resolveComponent("AppPagesWhatWeDo")!
  const _component_AppPagesHowWeDo = _resolveComponent("AppPagesHowWeDo")!
  const _component_AppSendRequest = _resolveComponent("AppSendRequest")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      name: "bubble",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.loader)
          ? (_openBlock(), _createBlock(_component_AppLoader, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_AppPagesBanner, { pageData: _ctx.pageData }, null, 8, ["pageData"]),
              _createVNode(_component_AppGallery, { pageData: _ctx.pageData }, null, 8, ["pageData"]),
              _createVNode(_component_AppPagesWhatWeDo, { pageData: _ctx.pageData }, null, 8, ["pageData"]),
              _createVNode(_component_AppPagesHowWeDo, { pageData: _ctx.pageData }, null, 8, ["pageData"]),
              _createVNode(_component_AppSendRequest, { pageData: _ctx.pageData }, null, 8, ["pageData"])
            ]))
      ]),
      _: 1
    })
  ]))
}