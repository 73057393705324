import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a4d8148e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-wrapper" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container", _ctx.variant])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["sendRequest-block", {
            'modal':_ctx.variant,
            'wow-total animate__fadeInUp': !_ctx.variant,
          }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", {
          class: "title",
          innerHTML: _ctx.pageData['blueBannerTitle']
        }, null, 8, _hoisted_2),
        _createElementVNode("a", {
          target: "_blank",
          href: _ctx.pageData['blueBannerButtonLink'],
          class: "btn btn-white btn-sendRequest"
        }, _toDisplayString(_ctx.pageData['blueBannerButtonText']), 9, _hoisted_3)
      ])
    ], 2)
  ], 2))
}