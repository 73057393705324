
import {defineComponent} from "vue";

export default defineComponent({
  name: "AppSendRequest",
  props: {
    pageData:Object,
    variant:String,
  },
})
