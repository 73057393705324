
  import AppLoader from "@/components/AppLoader.vue";
  import {defineComponent} from "vue";
  export default defineComponent({
    name: "ErrorView",
    components: { AppLoader},
    emits:{
      loaderAction:(trigger: boolean) => trigger,
    },
    props: {
      loader:Boolean
    },
    mounted() {
      this.$emit("loaderAction", true);
      setTimeout(()=> this.$emit("loaderAction", false),1000)
    },
})
