
  import {defineComponent} from "vue";
  import AppCommonLayout from "@/layouts/AppCommonLayout.vue";
  import AppEmptyLayout from "@/layouts/AppEmptyLayout.vue";
  export default defineComponent({
    components: {AppCommonLayout, AppEmptyLayout},
    data () {
      return{
        commonData: {}
      }
    },
    computed: {
      layout(){
        return this.$route.name === "confirmed meeting"
      }
    },
    async beforeCreate() {
      try {
        this.commonData = (await (await fetch('https://admin.esthete.studio//wp-json/acf/v2/options/')).json())['acf'];
      } catch (e){
        console.error(e)
      }
    }
  })
