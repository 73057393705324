import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppEmptyLayout = _resolveComponent("AppEmptyLayout")!
  const _component_AppCommonLayout = _resolveComponent("AppCommonLayout")!

  return (_ctx.layout)
    ? (_openBlock(), _createBlock(_component_AppEmptyLayout, {
        key: 0,
        commonData: _ctx.commonData
      }, null, 8, ["commonData"]))
    : (_openBlock(), _createBlock(_component_AppCommonLayout, {
        key: 1,
        commonData: _ctx.commonData
      }, null, 8, ["commonData"]))
}