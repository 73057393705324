
  import {defineComponent} from "vue";

  export default defineComponent({
    name: "AppGalleryModalVideo",
    props: {
      mediaData: Object
    },
    methods: {
      start(){
        (this.$refs.videoMain as HTMLVideoElement).play();
        (this.$refs.videoPoster as HTMLElement).remove();
        (this.$refs.playButton as HTMLElement).remove();
      }
    }
  })
