
  // Import Swiper Vue.js components
  import {defineComponent, ref} from 'vue';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { EffectCreative, Navigation, Pagination } from 'swiper';
  // Import Swiper styles
  import 'swiper/css';
  import "swiper/css/effect-creative";

  export default defineComponent({
    name: "AppHomeReviews",
    props: {
      pageData: Object
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        isDesktop: window.matchMedia('(min-width: 1110px)').matches
      }
    },
    setup() {
      const prev = ref(null);
      const next = ref(null);
      const pagination = ref(null);
      return {
        modules: [Navigation, Pagination, EffectCreative ],
        prev,
        next,
        pagination
      };
    },
  })
