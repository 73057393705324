
import {defineComponent} from "vue";

export default defineComponent({
    name: "ConfirmedMeetingView",
    props: {
      commonData: Object
    },
    data(){
      return {
        dataInfo: this.$route.query,
      }
    },
    computed:{
      startTime(){
        const gettingDate = new Date(this.dataInfo['event_start_time'] as string);
        const options: Intl.DateTimeFormatOptions = {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        };
        const filteredDate = new Intl.DateTimeFormat('en-US', options).formatToParts(gettingDate).filter(el => el.type !== 'literal');
        const {hour, minute, dayPeriod} = Object.fromEntries(filteredDate.map(item => [item.type, item.value]));
        return `${hour}:${minute}${dayPeriod.toLowerCase()}`
      },
      endTime(){
        const gettingDate = new Date(this.dataInfo['event_end_time'] as string);
        const options: Intl.DateTimeFormatOptions = {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
          day: 'numeric',
          weekday: 'long',
          month: 'long',
          year: 'numeric'
        };
        const filteredDate = new Intl.DateTimeFormat('en-US', options).formatToParts(gettingDate).filter(el => el.type !== 'literal');
        const {hour, minute, dayPeriod, day, weekday, month, year} = Object.fromEntries(filteredDate.map(item => [item.type, item.value]));
        return `${hour}:${minute}${dayPeriod.toLowerCase()}, ${weekday}, ${month} ${day}, ${year}`
      },
      timeZone(){
        const gettingDate = new Date(this.dataInfo['event_end_time'] as string);
        const options: Intl.DateTimeFormatOptions = {
          timeZoneName:'long'
        };
        const filteredDate = new Intl.DateTimeFormat('en-US', options).formatToParts(gettingDate).filter(el => el.type !== 'literal');
        const {timeZoneName} =  Object.fromEntries(filteredDate.map(item => [item.type, item.value]));
        return timeZoneName
      }
    }
  })
