import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44048ded"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "devConcept-block" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "difference-list" }
const _hoisted_5 = { class: "difference-el-video-wrapper wow-total animate__fadeInUp" }
const _hoisted_6 = { class: "difference-el-video" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "video-title" }
const _hoisted_9 = { class: "difference-el-title wow-total animate__fadeInUp" }
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", {
        class: "title wow-total animate__fadeInUp",
        innerHTML: _ctx.pageData['devConceptTitle']
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageData['devConceptEls'], (el) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "difference-el",
            key: el['devConceptElsVideoText']
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("video", {
                  src: el['devConceptElsVideo'],
                  class: "video",
                  loop: "",
                  muted: "",
                  autoplay: "",
                  playsinline: ""
                }, null, 8, _hoisted_7),
                _createElementVNode("h3", _hoisted_8, _toDisplayString(el['devConceptElsVideoText']), 1)
              ])
            ]),
            _createElementVNode("h4", _hoisted_9, _toDisplayString(el['devConceptElsTitle']), 1),
            _createElementVNode("p", {
              class: "difference-el-text wow-total animate__fadeInUp",
              innerHTML: el['devConceptElsText']
            }, null, 8, _hoisted_10)
          ]))
        }), 128))
      ])
    ])
  ]))
}