import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppMobileMenu = _resolveComponent("AppMobileMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_appFooter = _resolveComponent("appFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.loader)
      ? (_openBlock(), _createBlock(_component_AppHeader, {
          key: 0,
          playPreview: _ctx.headerPreview,
          commonData: _ctx.commonData,
          onMobileMenu: _ctx.mobileMenu
        }, null, 8, ["playPreview", "commonData", "onMobileMenu"]))
      : _createCommentVNode("", true),
    _createVNode(_component_AppMobileMenu, {
      commonData: _ctx.commonData,
      onMobileMenu: _ctx.mobileMenu,
      mobileMenuActive: _ctx.mobileMenuActive
    }, null, 8, ["commonData", "onMobileMenu", "mobileMenuActive"]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          preview: _ctx.preview,
          onPreviewAction: _ctx.previewState,
          loader: _ctx.loader,
          onLoaderAction: _ctx.showLoader
        }, null, 40, ["preview", "onPreviewAction", "loader", "onLoaderAction"]))
      ]),
      _: 1
    }),
    (!_ctx.loader)
      ? (_openBlock(), _createBlock(_component_appFooter, {
          key: 1,
          commonData: _ctx.commonData
        }, null, 8, ["commonData"]))
      : _createCommentVNode("", true)
  ], 64))
}